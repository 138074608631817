















































import { Component, Prop, Vue } from 'vue-property-decorator';
import CardRow from '@/components/CardRow.vue';
import { ConservatorshipDetails, WriteAddress } from '@/modules/conservatorship/types';
import { MapAddressToWriteAddress } from '@/modules/conservatorship/model';

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');
const ChangeAddressDialog = () => import('../dialogs/ChangeAddressDialog.vue');

@Component({
  components: { ChangeAddressDialog, CreateLetterButton, CardRow }
})
export default class AddressCard extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  get receiver(): WriteAddress {
    return MapAddressToWriteAddress(this.conservatorship.conservatee.address);
  }
}
