






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import ConservateeCard from '../cards/ConservateeCard.vue';
import ConservatorshipCard from '../cards/ConservatorshipCard.vue';
import AddressCard from '../cards/AddressCard.vue';
import NoteCard from '../cards/NoteCard.vue';

const ScopeOfFunctionsPlugin = () => import('@/modules/conservatorshipToScopeOfFunctions/components/ScopeOfFunctionsPlugin.vue');

@Component({
  components: {
    ScopeOfFunctionsPlugin,
    ConservateeCard,
    ConservatorshipCard,
    AddressCard,
    NoteCard
  }
})
export default class ConservatorshipTab extends Vue {
  @Prop({ type: Object }) conservatorship?: ConservatorshipDetails;
}
