



















import { Component, Prop, Vue } from 'vue-property-decorator';
import CardRow from '@/components/CardRow.vue';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';

const WriteNoteDialog = () => import('../dialogs/WriteNoteDialog.vue');

@Component({
  components: { WriteNoteDialog, CardRow }
})
export default class NoteCard extends Vue {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;
}
