






























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { isArchived, isClosed } from '@/modules/conservatorship/helper';
import CardRow from '@/components/CardRow.vue';
import { ConservatorshipDetails, State } from '@/modules/conservatorship/types';
import { AuthorizationMixin } from "@/views/mixin";

const ConcernedCourtChangeDialog = () => import('../dialogs/ConcernedCourtChangeDialog.vue');
const ConservatorChangeDialog = () => import('../dialogs/ConservatorChangeDialog.vue');
const BeginCorrectDialog = () => import('../dialogs/BeginCorrectDialog.vue');
const ConvertInAssignedDialog = () => import('../dialogs/ConvertInAssignedDialog.vue');
const CorrectAssignedDialog = () => import('../dialogs/CorrectAssignedDialog.vue');
const StateChangeDialog = () => import('../dialogs/StateChangeDialog.vue');

const CreateLetterButton = () => import('@/modules/correspondence/components/CreateLetterButton.vue');
const ContactPersonsDialog = () => import('@/modules/conservatorshipToContact/components/ContactPersonsDialog.vue');

@Component({
  components: {
    ContactPersonsDialog,
    CorrectAssignedDialog,
    ConvertInAssignedDialog,
    BeginCorrectDialog,
    ConservatorChangeDialog,
    ConcernedCourtChangeDialog,
    StateChangeDialog,
    CreateLetterButton,
    CardRow
  }
})
export default class ConservatorshipCard extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  isClosed(state: State) {
    return isClosed(state);
  }

  get visible(): boolean {
    return !isArchived(this.conservatorship.state)
        && this.$vuetify.breakpoint.mdAndUp
        && (this.isAdmin() || this.isConservator());
  }
}
