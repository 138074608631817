
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { differenceInMilliseconds, format } from '@/filter/dateFormat';
import PhoneRow from '@/components/PhoneRow.vue';
import CardRow from '@/components/CardRow.vue';
import { ConservatorshipDetails } from '@/modules/conservatorship/types';
import { isArchived } from '@/modules/conservatorship/helper';
import { AuthorizationMixin } from "@/views/mixin";

const BirthdayCorrectDialog = () => import('../dialogs/BirthdayCorrectDialog.vue');
const PlacementChangeDialog = () => import('../dialogs/PlacementChangeDialog.vue');
const FinancialChangeDialog = () => import('../dialogs/FinancialChangeDialog.vue');
const MobileNumberAddDialog = () => import('../dialogs/MobileNumberAddDialog.vue');
const PublicNumberAddDialog = () => import('../dialogs/PublicNumberAddDialog.vue');
const PrivateNumberAddDialog = () => import('../dialogs/PrivateNumberAddDialog.vue');
const ListenableDialog = () => import('../dialogs/ListenableDialog.vue');

@Component({
  components: {
    ListenableDialog,
    PrivateNumberAddDialog,
    PublicNumberAddDialog,
    MobileNumberAddDialog,
    FinancialChangeDialog,
    PlacementChangeDialog,
    BirthdayCorrectDialog,
    CardRow,
    PhoneRow
  }
})
export default class ConservateeCard extends AuthorizationMixin {
  @Prop({ type: Object, required: true }) conservatorship!: ConservatorshipDetails;

  get birthday(): string {
    const ms = differenceInMilliseconds(Date.now(), this.conservatorship.conservatee.birthday);
    const age = Math.abs((new Date(ms)).getUTCFullYear() - 1970);

    return `${format(this.conservatorship.conservatee.birthday, 'dd.MM.yyyy')} (${age})`;
  }

  get visible(): boolean {
    return !isArchived(this.conservatorship.state)
        && this.$vuetify.breakpoint.mdAndUp
        && (this.isAdmin() || this.isConservator());
  }
}
